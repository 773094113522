export default {
  lightRed: '#fc3031',
  red: '#fe4942',
  darkRed: '#e60647',
  dark: '#262626',
  lightGray: '#f8f8f8',
  lightGrayAlt: '#d9d9d9',
  gray: '#eee',
  darkGray: '#cacece',
  darkGrayAlt: '#808080',
  lightBlack: '#2a2929',
};
