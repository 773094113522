exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-offer-item-js": () => import("./../../../src/templates/OfferItem.js" /* webpackChunkName: "component---src-templates-offer-item-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/Offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-portfolio-item-js": () => import("./../../../src/templates/PortfolioItem.js" /* webpackChunkName: "component---src-templates-portfolio-item-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/Portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-technologies-item-js": () => import("./../../../src/templates/TechnologiesItem.js" /* webpackChunkName: "component---src-templates-technologies-item-js" */),
  "component---src-templates-technologies-js": () => import("./../../../src/templates/Technologies.js" /* webpackChunkName: "component---src-templates-technologies-js" */)
}

